<template>
  <div class="account-journal-init-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AccountJournalInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="finishAccountJournalInit"
            :text="$t('结束初始化')"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox id="BaseInfo" :title="$t('基本信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据编号`)">
                    <a-input disabled v-model="formData.orderSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`账簿`)" required>
                    <SelectAccountSet
                      :code="formData.accountSetUnionCode"
                      :disabled="isDetail"
                      @change="changeAccountSet"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <a-input :disabled="true" :value="formData.businessUnitName" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)" required>
                    <SelectCurrency :code="formData.currency" :disabled="isDetail" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`初始化期间`)" required>
                    <CommonQuerySelect
                      api="queryAccountingCalendarDetailPage"
                      valueName="accounting_period_code"
                      labelName="accounting_period_name"
                      :code.sync="formData.accountingPeriod"
                      :placeholder="$t(`初始化期间`)"
                      :disabled="isDetail"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input
                      :value="$store.getters.initAccountBalanceStatusMapping[formData.status]"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="AccountJournalInfo" :title="$t('明细')">
              <template v-slot:header>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="addLineHandle">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml20" @click="deleteLineHandle">
                    {{ $t('删行') }}
                  </span>
                </div>
              </template>
              <a-table
                size="small"
                bordered
                :rowKey="(record, index) => index"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                :columns="columns"
                :dataSource="formData.detailList"
                :pagination="false"
                :scroll="{ x: 1200 }"
              >
                <template slot="accountName" slot-scope="text, record, index">
                  <span v-if="isDisabled">{{ record.accountName }}</span>
                  <a-input
                    v-else
                    readOnly
                    :value="record.accountName"
                    @click="showAccountModal(index)"
                    :placeholder="$t('科目名称')"
                  />
                </template>
                <template slot="dimensionDetail" slot-scope="text, record">
                  <span
                    v-if="record.dimensionLibList?.length > 0 && !isDisabled"
                    class="text-link"
                    @click="showDimensionModal(record)"
                  >
                    {{ $t('录入明细') }}
                  </span>
                </template>
                <template slot="debitAmount" slot-scope="text, record">
                  <PriceInput
                    :value.sync="record.debitAmount"
                    :disabled="isDisabled"
                    :showTxt="isDisabled || record.dimensionLibList?.length > 0"
                    :currency="record.currency"
                    @blur="
                      (val) => {
                        blurHandle(val, record, 'creditAmount')
                      }
                    "
                  />
                </template>
                <template slot="creditAmount" slot-scope="text, record">
                  <PriceInput
                    :value.sync="record.creditAmount"
                    :disabled="isDisabled"
                    :showTxt="isDisabled || record.dimensionLibList?.length > 0"
                    :currency="record.currency"
                    @blur="
                      (val) => {
                        blurHandle(val, record, 'debitAmount')
                      }
                    "
                  />
                </template>
              </a-table>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal
      :keyboard="false"
      :maskClosable="false"
      :title="modalTitle"
      width="800px"
      v-model="dimensionVisible"
      @ok="handleDimensionOk"
      @cancel="dimensionVisible = false"
    >
      <div class="option-btns flex-end pr20" v-if="!isDisabled">
        <span class="text-link fz16" @click="addDetailLineHandle">
          {{ $t('增行') }}
        </span>
        <span class="text-link fz16 ml20" @click="deleteDetailLineHandle">
          {{ $t('删行') }}
        </span>
      </div>
      <a-table
        size="small"
        bordered
        :row-selection="{
          selectedRowKeys: selectedRowKeys2,
          onChange: onSelectChange2,
        }"
        :rowKey="(record, index) => index"
        :columns="detailColumns"
        :dataSource="dimensionData.dimensionList"
        :pagination="false"
      >
        <template slot="dimensionList" slot-scope="text, record">
          <a-input
            readOnly
            class="ant-input ellipsis"
            :disabled="isDisabled"
            :value="record.dimensionListName"
            @click="showDimensionDetailModal(record)"
          />
        </template>
        <template slot="debitAmount" slot-scope="text, record">
          <PriceInput
            :value.sync="record.debitAmount"
            :disabled="isDisabled"
            :showTxt="isDisabled || record.dimensionList?.length > 0"
            :currency="record.currency"
            @blur="
              (val) => {
                blurHandle(val, record, 'creditAmount')
              }
            "
          />
        </template>
        <template slot="creditAmount" slot-scope="text, record">
          <PriceInput
            :value.sync="record.creditAmount"
            :disabled="isDisabled"
            :showTxt="isDisabled || record.dimensionList?.length > 0"
            :currency="record.currency"
            @blur="
              (val) => {
                blurHandle(val, record, 'debitAmount')
              }
            "
          />
        </template>
      </a-table>
    </a-modal>
    <SelectDimensionModal
      :visible.sync="dimensionDetailVisible"
      :businessUnitCode="formData.businessUnitCode"
      :list="[dimensionDetailData]"
      @ok="handleDimensionDetailOk"
    />
    <SelectAccountModal ref="SelectAccountModalRef" @ok="selectAccountHandle" />
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import BottomBtns from '@component/bottomBtns'
import SelectAccountSet from '@component/selectAccountSet'
import SelectCurrency from '@component/selectCurrency'
import OperationLog from '@component/operationLog'
import SelectDimensionModal from '@component/selectDimensionModal'
import SelectAccountModal from '@/component/selectAccountModal'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
export default {
  name: 'AccountJournalInitDetail',
  components: {
    BottomBtns,
    OperationLog,
    SelectAccountSet,
    SelectCurrency,
    SelectDimensionModal,
    SelectAccountModal,
  },
  data() {
    return {
      docType: 'AccountJournalInit',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        status: 'DRAFT',
        businessUnitCode: undefined,
        businessUnitName: undefined,
        currency: undefined,
        detailList: [],
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      selectedRowKeys: [],
      selectedRowKeys2: [],
      dimensionData: {
        dimensionList: [],
      },
      dimensionVisible: false,

      dimensionDetailVisible: false,
      dimensionDetailData: {
        dimensionList: [],
      },
      modalTitle: '',
      modalIndex: 0,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
    columns() {
      return [
        {
          title: this.$t('科目名称'),
          dataIndex: 'accountName',
          align: 'center',
          scopedSlots: { customRender: 'accountName' },
        },
        {
          title: this.$t('科目编码'),
          dataIndex: 'accountCode',
          align: 'center',
        },
        {
          title: this.$t('明细'),
          dataIndex: 'detail',
          align: 'center',
          scopedSlots: { customRender: 'dimensionDetail' },
        },
        {
          title: this.$t('期初余额'),
          dataIndex: 'balance',
          children: [
            {
              title: this.$t('借方原币'),
              dataIndex: 'debitAmount',
              align: 'center',
              scopedSlots: { customRender: 'debitAmount' },
            },
            {
              title: this.$t('贷方原币'),
              dataIndex: 'creditAmount',
              align: 'center',
              scopedSlots: { customRender: 'creditAmount' },
            },
          ],
        },
      ]
    },
    detailColumns() {
      return [
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionList',
          scopedSlots: { customRender: 'dimensionList' },
        },
        {
          title: this.$t('期初余额'),
          dataIndex: 'balance',
          children: [
            {
              title: this.$t('借方原币'),
              dataIndex: 'debitAmount',
              align: 'center',
              scopedSlots: { customRender: 'debitAmount' },
            },
            {
              title: this.$t('贷方原币'),
              dataIndex: 'creditAmount',
              align: 'center',
              scopedSlots: { customRender: 'creditAmount' },
            },
          ],
        },
      ]
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.accountingPeriod = moment().format('YYYY-MM')
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.changeBU(value || {})
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      this.formData.currency = value.currency
    },
    async getDetailInfo() {
      if (this.id) {
        this.loading = true
        await http({
          url: api.getAccountJournalInitDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            result.detailList.forEach((item) => {
              if (item.dimensionList.length > 0) {
                this.getShowName(item)
                let debitAmount = 0,
                  creditAmount = 0
                item.dimensionList.forEach((item) => {
                  if (item.balanceDirection === 'DEBIT') {
                    item.debitAmount = item.balance
                    debitAmount += +item.balance
                  } else {
                    item.creditAmount = item.balance
                    creditAmount += +item.balance
                  }
                })
                item.debitAmount = debitAmount
                item.creditAmount = creditAmount
                this.$set(item, 'dimensionLibList', [
                  {
                    accountingDimensionSourceTable: item.accountingDimensionSourceTable,
                    accountingDimensionCode: item.accountingDimensionCode,
                    accountingDimensionName: item.accountingDimensionName,
                    accountingDimensionValueCode: '',
                    accountingDimensionValueName: '',
                  },
                ])
              } else {
                if (item.balanceDirection === 'CREDIT') {
                  item.creditAmount = item.balance
                } else {
                  item.debitAmount = item.balance
                }
              }
            })
            this.formData = deepClone(result)
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
          },
        })
        this.loading = false
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    backForm(back = 0) {
      goBack('accountJournalInitList', back)
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      data.status = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList.forEach((item) => {
        if (item.dimensionList.length === 0) {
          if (item.debitAmount) {
            item.balanceDirection = 'DEBIT'
            item.balance = item.debitAmount
          } else {
            item.balanceDirection = 'CREDIT'
            item.balance = item.creditAmount
          }
        } else {
          let balance = 0
          item.dimensionList.forEach((item) => {
            balance += item.balanceDirection === 'DEBIT' ? +item.debitAmount : -item.creditAmount
          })
          item.balance = balance
          item.balanceDirection = balance >= 0 ? 'DEBIT' : 'CREDIT'
        }
      })
      await http({
        url: api.createOrUpdateAccountJournalInit,
        data,
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    addLineHandle() {
      this.formData.detailList.push({})
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
    },
    addDetailLineHandle() {
      const data = {
        ...this.dimensionData.dimensionLibList[0],
        balance: '',
        balanceDirection: '',
        currency: this.formData.currency,
      }
      this.dimensionData.dimensionList.push(data)
    },
    deleteDetailLineHandle() {
      if (this.selectedRowKeys2.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.dimensionData.dimensionList = this.dimensionData.dimensionList.filter(
        (item, index) => !this.selectedRowKeys2.includes(index)
      )
      this.selectedRowKeys2 = []
    },
    showDimensionModal(record) {
      if (this.isDisabled) return
      this.dimensionVisible = true
      this.dimensionData = record
      this.modalTitle = `【${record.accountName}】${this.$t('明细数据录入')}`
      if (this.dimensionData.dimensionList.length === 0) {
        this.addDetailLineHandle()
      }
    },
    showDimensionDetailModal(record) {
      if (this.isDisabled) return
      this.dimensionDetailVisible = true
      this.dimensionDetailData = record
    },
    handleDimensionOk() {
      //判断dimensionData.dimensionList有没有重复的 唯一键accountingDimensionValueCode
      let list = this.dimensionData.dimensionList.map((item) => item.accountingDimensionValueCode)
      let set = new Set(list)
      if (set.size !== list.length) {
        this.$message.warning(this.$t(`导入的数据中存在重复数据`))
        return
      }
      this.dimensionVisible = false
      let debitAmount = 0,
        creditAmount = 0
      this.dimensionData.dimensionList.forEach((item) => {
        if (item.debitAmount) {
          item.balanceDirection = 'DEBIT'
          item.balance = item.debitAmount
          debitAmount += +item.debitAmount
        } else {
          item.balanceDirection = 'CREDIT'
          item.balance = item.creditAmount
          creditAmount += +item.creditAmount
        }
      })
      this.dimensionData.debitAmount = debitAmount
      this.dimensionData.creditAmount = creditAmount
    },
    handleDimensionDetailOk() {
      this.getShowName(this.dimensionData)
    },
    getShowName(data) {
      data.dimensionList.forEach((item) => {
        let name = `${item.accountingDimensionName}: ${item.accountingDimensionValueName}`
        this.$set(item, 'dimensionListName', name)
      })
    },
    blurHandle(val, record, name) {
      if (val) {
        this.$set(record, name, '')
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelectChange2(selectedRowKeys) {
      this.selectedRowKeys2 = selectedRowKeys
    },
    showAccountModal(index) {
      this.modalIndex = index
      this.$refs.SelectAccountModalRef.open()
    },
    selectAccountHandle(list) {
      for (let i = 0; i < list.length; i++) {
        const data = this.getAccountDimension(list[i])
        this.formData.detailList.splice(this.modalIndex + i, i === 0 ? 1 : 0, data)
      }
    },
    getAccountDimension({ id, code, full_name, dimension_list }) {
      if (!id) return
      const data = {
        summary: undefined,
        currency: this.formData.currency,
        dimensionLibList: [],
        dimensionList: [],
        accountCode: code,
        accountName: full_name,
      }
      if (dimension_list.length > 0) {
        let list = dimension_list.map((item) => ({
          accountingDimensionSourceTable: item.source_table,
          accountingDimensionCode: item.value_code,
          accountingDimensionName: item.value_name,
          accountingDimensionValueCode: '',
          accountingDimensionValueName: '',
        }))
        data.dimensionLibList = list
      }
      return data
    },
  },
}
</script>
<style scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
}
</style>
